import { Card } from 'components';
import styles from './Achievements.module.scss';

const Achievements = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Card title="Досягнення">
          <div>ff</div>
        </Card>
      </div>
    </div>
  );
};

export default Achievements;
